import {Component, Input, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {BackendService} from '../../services/backend.service';
import {Storage} from '@ionic/storage';
import {Product} from '../../entities/product';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-loyalty-payment-accept',
    templateUrl: './loyalty-payment-accept.page.html',
    styleUrls: ['./loyalty-payment-accept.page.scss'],
})
export class LoyaltyPaymentAcceptPage implements OnInit {

    @Input()
    product: Product;
    user: any;
    selectedAdditions: Product[] = [];

    customer: any;

    interval: any;

    orderId;
    orderText = "";
    location;

    constructor(private modalCtrl: ModalController, private backendService: BackendService, private storage: Storage,
                private loadingController: LoadingController, private translate: TranslateService) { }

    ngOnInit() {
        this.orderText = `${this.product.name || '-'} (${(Math.floor(this.product.price)) || '-'} kr.)`;
        if (this.selectedAdditions.length > 0) {
            this.selectedAdditions.forEach(add => {
                this.orderText += `, ${add.name || '-'} (${(Math.floor(add.price)) || '-'} kr.)`;
            })
        }
        this.backendService.createOrderForLoyalty(this.product.price, this.product.sku).subscribe(resp => {
            this.orderId = resp.id;
            this.backendService.getLoyaltyCustomer(this.user.loyalty_customer_id).subscribe(resp => {
                this.customer = resp;
            })
        })
    }

    dismiss(status, queueNumber = null) {
        clearInterval(this.interval);
        let paymentStatus = 2;
        if (status === 'captured')
            paymentStatus = 3;
        this.backendService.updateOrderForLoyalty(this.orderId, paymentStatus).subscribe(resp => {
            this.modalCtrl.dismiss({
                'status': status,
                'queueNumber': queueNumber
            })
        })
    }

    async clickConfirm() {
        const loading = await this.loadingController.create({
            animated: false,
            duration: 30000
        });

        await loading.present().then(() => {
            this.backendService.updateOrderForLoyalty(this.orderId, 4).subscribe(resp => {
                let additions: string[] = [];
                if (this.selectedAdditions && this.selectedAdditions.length > 0) {
                    additions = [];
                    this.selectedAdditions.forEach(add => {
                        additions.push(add.sku);
                    })
                }
                this.backendService.runLoyalty(this.product.sku, this.product.customer_id, this.user.loyalty_code, additions, this.orderId).subscribe(resp => {
                    loading.dismiss();

                    this.dismiss('captured', resp.queueNumber);
                }, error => {
                    console.log(error);
                    loading.dismiss();
                    this.dismiss('error', null);
                });
            });
        });

    }
}
