import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../../entities/product';

@Component({
    selector: 'app-club-payment-complete',
    templateUrl: './club-payment-complete.component.html',
    styleUrls: ['./club-payment-complete.component.scss'],
})
export class ClubPaymentCompleteComponent implements OnInit {

    @Input()
    product: Product;
    paymentType: String;
    receiptAllowed: boolean;
    queueNumber: Number = 0;
    userInfo: any;
    balance: any;
    selectedAdditions: Product[] = [];
    queueText: String;
    location;

    timer = 30;
    interval;

    orderText = "";

    orderPrice = 0;

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {
        if(this.queueNumber > 0) {
            this.queueText = "Kø nummer: #" + String(this.queueNumber).slice(-2);
        }
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)

        this.orderPrice = Number(this.product.price)
        this.orderText = `${this.product.name || '-'} (${(Math.floor(this.product.price)) || '-'} kr.)`;
        if (this.selectedAdditions.length > 0) {
            this.selectedAdditions.forEach(add => {
                this.orderText += `, ${add.name || '-'} (${(Math.floor(add.price)) || '-'} kr.)`;
                this.orderPrice += Number(add.price);
            })
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
