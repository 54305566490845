import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {BackendService} from "../../services/backend.service";
import {Product} from '../../entities/product';
import {LoyaltyPaymentAcceptPage} from '../loyalty-payment-accept/loyalty-payment-accept.page';
import {AuthService} from '../../services/auth.service';
import {ClubPaymentAcceptComponent} from '../club/club-payment-accept/club-payment-accept.component';

@Component({
  selector: 'app-loyalty-payment-keypad',
  templateUrl: './loyalty-payment-keypad.component.html',
  styleUrls: ['./loyalty-payment-keypad.component.scss'],
})

export class LoyaltyPaymentKeypadComponent implements OnInit {

    @Input()
    product: Product;
    clubAllowed: boolean;
    loyaltyAllowed: boolean;
    selectedAdditions: string[] = [];
    QRCode: string;
    interval: any;

    code_wrong = false;

    display: string[] = [];
    location;

    constructor(
        private modalCtrl: ModalController, 
        private backendService: BackendService, 
        private alertController: AlertController, 
        private auth: AuthService
    ){}

    ngOnInit() {}

    async dismiss(status, data = undefined, queueNumber = null) {
        clearInterval(this.interval);
        if (!data) {
            data = {'status': status, 'queueNumber': queueNumber}
        }
        await this.modalCtrl.dismiss(data)
    }

    keypadDelete() {
        this.code_wrong = false;
        this.display.pop();
    }

    keypadClear() {
        this.code_wrong = false;
        this.display = [];
    }

    async keypadClick(number) {
        if (this.display.length < 6) {
            this.display.push(number)
        }

        if (this.display.length === 6) {
            await this.keypadFilled()
        }
    }

    async keypadFilled() {
            let value = "";
            this.display.forEach(char => {
                value += char;
            })

        if (this.clubAllowed && this.loyaltyAllowed) {
            this.auth.signInClub(value, this.product.customer_id).subscribe(async resp => {
                await this.launchAcceptClubModal(this.product, resp.user, resp.userInfo, resp.balance, resp.token, this.selectedAdditions);
            }, async () => {
                this.codeAttemptLoyalty(value);
            });
        }
        if (this.clubAllowed && !this.loyaltyAllowed) {
            this.auth.signInClub(value, this.product.customer_id).subscribe(async resp => {
                await this.launchAcceptClubModal(this.product, resp.user, resp.userInfo, resp.balance, resp.token, this.selectedAdditions);
            }, async () => {
                this.code_wrong = true;
            });
        }
        if (!this.clubAllowed && this.loyaltyAllowed) {
            this.codeAttemptLoyalty(value);
        }
    }

    codeAttemptLoyalty(value) {
        this.backendService.GetLoyaltyUserByLoyaltyCode(this.product.customer_id, value).subscribe(async resp => {
            if (resp == null) {
                this.code_wrong = true;
            } else {
                if (resp.id)
                    await this.launchAcceptModal(this.product, resp, this.selectedAdditions)
                else
                    this.code_wrong = true;
            }
        }, async error => {
            console.log(error);
            await this.presentAlert('Kunne ikke kontrollere kode');
        })
    }

    getDisplayItem(position) {
        let length = this.display.length;
        if (position === (length - 1)) {
            return this.display[position]
        }
        if (position >= length) {
            return "";
        }
        if (position < (length - 1)) {
            return "*";
        }
    }

    async presentAlert(message: string, auto_dismiss: boolean = false) {
        const alert = await this.alertController.create({
            animated: false,
            header: message,
            buttons: [{
                text: 'OK'
            }]
        });

        await alert.present();
        if (auto_dismiss) {
            setTimeout(() => {
                alert.dismiss();
            },3000);
        }
    }

    async launchAcceptModal(product: Product, user, additions) {
        let component: any = LoyaltyPaymentAcceptPage;
        let cssClass: string = 'loyalty-payment-accept-modal';

        let modal = await this.modalCtrl.create({
            animated: false,
            component: component,
            cssClass: cssClass,
            componentProps: {
                'product': product,
                'user': user,
                'selectedAdditions': additions
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(modal => {
            if (modal.data != null && modal.data.status != 'dismiss') {
                this.dismiss(modal.data.status, undefined, modal.data.queueNumber)
            } else {
                this.dismiss('dismiss')
            }
        })
    }

    async launchAcceptClubModal(product: Product, user, userInfo, balance, token, additions) {
        let component: any = ClubPaymentAcceptComponent;
        let cssClass: string = 'payment-result-modal';

        let modal = await this.modalCtrl.create({
            animated: false,
            component: component,
            cssClass: cssClass,
            componentProps: {
                'product': product,
                'user': user,
                'userInfo': userInfo,
                'balance': balance,
                'token': token,
                'selectedAdditions': additions
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(modal => {
            if (modal.data == undefined || modal.data.status == undefined) {
                this.dismiss('dismiss')
            } else if (modal.data.status == 'purchase') {
                this.dismiss(modal.data.status, modal.data);
            } else {
                this.dismiss(modal.data.status, modal.data)
            }
        })
    }
}
