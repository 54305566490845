import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {BackendService} from "../../services/backend.service";
import {Storage} from "@ionic/storage";
import {Product} from '../../entities/product';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {

  @Input()
  price: number;
  product_name: string;
  selectedAdditions: Product[] = [];

  QRCode: string;

  timer = 60;
  interval;

  orderText = "";
  location;

  constructor(private modalCtrl: ModalController, private backendService: BackendService, private storage: Storage) { }

  ngOnInit() {
    this.storage.get('pos_id').then(pos_id => {
      this.QRCode = "mobilepaypos://pos?id=" + pos_id + "&source=qr";
    })

    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        this.dismiss('timer')
      }
    }, 1000)
    this.orderText = `${this.product_name || '-'} - ${(Math.floor(this.price)) || '-'} kr.`;
    if (this.selectedAdditions && this.selectedAdditions.length > 0) {
      this.selectedAdditions.forEach(add => {
        this.orderText += `, ${add.name || '-'} - ${(Math.floor(add.price)) || '-'} kr.`;
      })
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

  dismiss(status) {
    clearInterval(this.interval);
    this.modalCtrl.dismiss({
      'status': status
    })
  }
}
