import { Component, OnInit } from '@angular/core';
import {Config} from '../entities/config';
import {version} from '../app.module'
import { AuthService } from '../services/auth.service';
import { BackendService } from '../services/backend.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  userConfig: Config;
  version: string;
  customer_id: number;
  location;

  constructor(
    private auth: AuthService,      
    private backendService: BackendService, 
    ) {
      this.version = version;
   }

  ngOnInit() {
    this.auth.getUserId().then(userId => {
      this.userConfig = this.getConfig(userId);
    });
  }

  getConfig(userId: number): Config {
    this.backendService.getPosConfig(userId).subscribe(async (config) => {
      return config;
    });
    return null;
  }
}
