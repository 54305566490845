import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';

@Component({
    selector: 'app-payment-failed',
    templateUrl: './payment-failed.component.html',
    styleUrls: ['./payment-failed.component.scss'],
})
export class PaymentFailedComponent implements OnInit {

    @Input()
    product: Product;
    paymentType: string;
    selectedAdditions: Product[] = [];

    value: number = 0;
    timer = 30;
    interval;

    orderText = "";
    location;

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)

        if(this.product) {
            this.orderText = `${this.product.name || '-'}`;
            if (this.paymentType != 'Kupon')
                this.orderText += ` - ${(Math.floor(this.product.price)) || '-'} kr.`
            if (this.selectedAdditions.length > 0) {
                this.selectedAdditions.forEach(add => {
                    this.orderText += `, ${add.name || '-'}`;
                    if (this.paymentType != 'Kupon')
                        this.orderText += ` - ${(Math.floor(add.price)) || '-'} kr.`
                })
            }
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }

    isContactless() {
        return this.paymentType === 'Kontaktløs';
    }
}
