import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';
import {BackendService} from '../../services/backend.service';
import {Moment} from 'moment';

@Component({
    selector: 'app-sms-receipt',
    templateUrl: './sms-receipt.component.html',
    styleUrls: ['./sms-receipt.component.scss'],
})
export class SMSReceiptComponent implements OnInit {

    product: Product;
    paymentType: string;
    vendor_info: any;
    orderTimestamp: Moment;
    receiptId: number;
    selectedAdditions: Product[] = [];

    order_time: string;
    order_date: string;

    math = Math

    phoneNumber = "";

    input_length = 8;

    baseTime = 40;
    warning_threshold = 10;

    value: number = 0;
    timer = this.baseTime;
    interval;
    location;

    constructor(private modalCtrl: ModalController, private backendService: BackendService,
                private loadingController: LoadingController) { }

    ngOnInit() {
        this.setTimer()

        this.order_time = this.orderTimestamp.format('HH:mm');
        this.order_date = this.orderTimestamp.format('D/M-YY');
    }

    setTimer() {
        clearInterval(this.interval)
        this.timer = this.baseTime;
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                clearInterval(this.interval)
                this.dismiss('timeout')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status, recipient = undefined) {
        this.modalCtrl.dismiss({
            'status': status,
            'recipient': recipient
        })
    }

    async sendSMS() {
        clearInterval(this.interval)
        const loading = await this.loadingController.create({
            animated: false,
            duration: 30000
        });

        await loading.present().then(() => {
            console.log('Sending SMS to: ' + this.phoneNumber)
            if (!this.selectedAdditions || this.selectedAdditions.length == 0) {
                this.backendService.sendSmsReceipt(this.phoneNumber, this.vendor_info.point_of_sale, this.paymentType, this.order_date, this.order_time, this.product.name,
                    this.product.price.toString(), this.vendor_info.vendor, this.vendor_info.vendor_address, this.vendor_info.vendor_cvr, this.receiptId).subscribe(() => {
                    loading.dismiss('dismiss');
                    this.dismiss('sms_success', this.phoneNumber);
                }, () => {
                    loading.dismiss('dismiss');
                    this.dismiss('sms_fail', this.phoneNumber);
                })
            } else if (this.selectedAdditions && this.selectedAdditions.length > 0) {
                let ad_prod = this.selectedAdditions[0];
                this.backendService.sendSmsReceiptAddition(this.phoneNumber, this.vendor_info.point_of_sale, this.paymentType, this.order_date, this.order_time, this.product.name,
                    this.product.price.toString(), ad_prod.name, ""+ad_prod.price, this.vendor_info.vendor, this.vendor_info.vendor_address, this.vendor_info.vendor_cvr, this.receiptId).subscribe(() => {
                    loading.dismiss('dismiss');
                    this.dismiss('sms_success', this.phoneNumber);
                }, () => {
                    loading.dismiss('dismiss');
                    this.dismiss('sms_fail', this.phoneNumber);
                })
            }
        })
        await loading.onDidDismiss().then(loadModal => {
            if (!loadModal.data) {
                this.dismiss('sms_fail', this.phoneNumber);
            }
        })
    }

    keypadClick(val) {
        this.setTimer()
        if (this.phoneNumber.length < 8) {
            this.phoneNumber = this.phoneNumber + val;
        }
    }
    keypadClear() {
        this.phoneNumber = "";
    }
    
    keypadDelete() {
        this.setTimer()
        if (this.phoneNumber.length > 0) {
            this.phoneNumber = this.phoneNumber.substr(0, this.phoneNumber.length - 1)
        }
    }
}
