import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-club-signup-fail',
    templateUrl: './club-signup-fail.component.html',
    styleUrls: ['./club-signup-fail.component.scss'],
})
export class ClubSignupFailComponent implements OnInit {

    timer = 30;
    interval;
    location;

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer');
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
