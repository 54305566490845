import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  timeInSec: number;

  value: number = 0;
  timer: number;

  interval;
  otherInterval;
  location;

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    this.timer = this.timeInSec;

    if (this.timeInSec == 10) {
      this.interval = setInterval(() => {
        this.value += this.timeInSec / 1000
      }, 100)
    }

    this.otherInterval = setInterval(() => {
      this.timer--;
      if (this.timer == 0) {
        clearInterval(this.interval)
        clearInterval(this.otherInterval)
        this.modalCtrl.dismiss()
      }
    }, 1000)
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    clearInterval(this.otherInterval);
  }


}
