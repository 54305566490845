import { Component, Input, OnInit, isDevMode } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';
import {version} from '../../app.module'

@Component({
  selector: 'app-products-selector',
  templateUrl: './products-selector.component.html',
  styleUrls: ['./products-selector.component.scss'],
})
export class ProductSelectorComponent implements OnInit {

  @Input()
  products: Product[];
  title: String;

  code: number;
  
  math = Math;
  interval;
  timer = 30;
  int_val = 0;
  isDevMode = isDevMode();
  version: string;
  location;

  constructor(private modalCtrl: ModalController) {
    this.version = version;
  }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        this.dismiss('timer')
      }
    }, 1000)
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

  dismiss(status) {
    this.modalCtrl.dismiss({
      'status': status,
    })
  }

  productSelected(product) {
    if (!product.enabled)
      this.dismiss('dismiss')
    this.modalCtrl.dismiss({
      'status': 'next',
      'product': product
    });
  }

}
