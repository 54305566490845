import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { LoyaltyPaymentAcceptPage } from './loyalty-payment-accept.page';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export class TranslationLoader implements TranslateLoader {
    constructor(private http: HttpClient) { }
  
    getTranslation(lang: string): Observable<any> {
        return this.http.get(environment.api + "translation/translation/1/0000/" + lang);
    }
  }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslationLoader(http);
}

const routes: Routes = [
    {
        path: '',
        component: LoyaltyPaymentAcceptPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        })
    ],
    declarations: [LoyaltyPaymentAcceptPage]
})
export class LoyaltyPaymentAcceptPageModule {}
