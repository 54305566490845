import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-loyalty-payment-complete',
    templateUrl: './loyalty-payment-complete.component.html',
    styleUrls: ['./loyalty-payment-complete.component.scss'],
})
export class LoyaltyPaymentCompleteComponent implements OnInit {

    @Input()
    queueNumber: Number = 0;
    orderText: String;
    queueText: String;

    timer = 30;
    interval;

    orderPrice = 0;
    location;

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {
        if(this.queueNumber > 0) {
            this.queueText = "Kø nummer: #" + String(this.queueNumber).slice(-2);
        }
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
