import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';
import {Config} from '../../entities/config';
import {Moment} from 'moment';

@Component({
    selector: 'app-payment-complete',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {

    @Input()
    product: Product;
    paymentType: String;
    userConfig: Config;
    orderTimestamp: Moment;
    receiptId: number;
    selectedAdditions: Product[] = [];

    order_time: string;
    order_date: string;

    math = Math

    timer = 30;
    interval;

    orderPrice = 0;
    location;

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {
        this.order_time = this.orderTimestamp.format('HH:mm');
        this.order_date = this.orderTimestamp.format('DD/MM-YYYY');

        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)

        this.orderPrice = Number(this.product.price)
        if (this.selectedAdditions.length > 0) {
            this.selectedAdditions.forEach(add => {
                this.orderPrice += Number(add.price);
            })
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }

    calcTax(price: number) {
        return (price / 5).toFixed(2).toString().replace(".",",");
    }
}
