import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {Storage} from "@ionic/storage";
import {BackendService} from '../../../services/backend.service';

@Component({
    selector: 'app-club-login-keypad',
    templateUrl: './club-login-keypad.page.html',
    styleUrls: ['./club-login-keypad.page.scss'],
})
export class ClubLoginKeypadPage implements OnInit {

    timer = 30;
    interval;
    location;

    display: string[] = [];

    constructor(private modalCtrl: ModalController, private backendService: BackendService, private storage: Storage, private alertController: AlertController) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status, code: string = undefined) {
        clearInterval(this.interval);
        this.modalCtrl.dismiss({
            'status': status,
            'code': code
        })
    }

    keypadDelete() {
        this.display.pop();
    }

    keypadClear() {
        this.display = [];
    }

    async keypadClick(number) {
        if (this.display.length < 6) {
            this.display.push(number)
        }

        if (this.display.length === 6) {
            await this.keypadFilled()
        }
    }

    async keypadFilled() {
            let value = "";
            this.display.forEach(char => {
                value += char;
            })
        console.log(value);

            this.dismiss('next', value)
    }

    getDisplayItem(position) {
        let length = this.display.length;
        if (position === (length - 1)) {
            return this.display[position]
        }
        if (position >= length) {
            return "";
        }

        if (position < (length - 1)) {
            return "*";
        }
    }

    async presentAlert(message: string, auto_dismiss: boolean = false) {
        const alert = await this.alertController.create({
            animated: false,
            header: message,
            buttons: [{
                text: 'OK'
            }]
        });

        await alert.present();
        if (auto_dismiss) {
            setTimeout(() => {
                alert.dismiss();
            },3000);
        }
    }
}
