import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-club-signup-name',
    templateUrl: './club-signup-name.component.html',
    styleUrls: ['./club-signup-name.component.scss'],
})
export class ClubSignupNameComponent implements OnInit {

    @Input()
    name: string;

    typed_name = "";

    baseTime = 40;
    warning_threshold = 10;

    timer = this.baseTime;
    interval;
    location;

    shifted: boolean = true;

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {
        this.setTimer()
        if (this.name) {
            this.typed_name = this.name;
            this.checkShift();
        }
    }

    setTimer() {
        clearInterval(this.interval)
        this.timer = this.baseTime;
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                clearInterval(this.interval)
                this.dismiss('timeout')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status, name = undefined) {
        this.modalCtrl.dismiss({
            'status': status,
            'name': name
        })
    }

    keypadClick(val) {
        this.setTimer()
        if (this.shifted)
            val = this.shiftedValue(val);
        this.typed_name = this.typed_name + val;

        this.checkShift();
    }

    keypadShiftToggle() {
        this.shifted = !this.shifted;
    }

    shiftedValue(val: string) {
        if (this.shifted)
            return val.toUpperCase();
        else
            return val.toLowerCase();
    }

    keypadDelete() {
        this.setTimer()
        if (this.typed_name.length > 0) {
            this.typed_name = this.typed_name.substr(0, this.typed_name.length - 1)
        }

        this.checkShift();
    }

    checkShift() {
        this.shifted = this.lastWasSpace() || this.typed_name.length == 0;
    }

    lastWasSpace() {
        return this.typed_name.substring(this.typed_name.length - 1) === " ";
    }

    displayString() {
        if (this.typed_name.length < 22) {
            return this.typed_name;
        }
        else {
            return "..." + this.typed_name.substr(this.typed_name.length - 21)
        }
    }
}
