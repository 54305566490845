import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient, private storage: Storage) { }

  useChip = false;

  SetFirebaseMessage(message: String, type: String) {

    // use chip
    if(type == "use_chip") {
      if(message == "true") {
        this.useChip = true;
      } else {
        this.useChip = false;
      }
    }

    return true;
  }

  saveFcmTokenToBackend(fcm_token: String): Observable<any> {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/setcomtoken';

        const body = {
          token: fcm_token
        };

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.post<any>(url, body, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        )
      });
    });
  }


}
