import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-club-info',
    templateUrl: './club-info.component.html',
    styleUrls: ['./club-info.component.scss'],
})
export class ClubInfoComponent implements OnInit {

    timer = 30;
    interval;

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
